import { useQueryResult } from '@/components/generic/query-result-handler'
import TicketCostType from '@/enums/ticketing-service/ticket-cost-type'
import OrderStatus from '@/enums/ticketing-service/order-status'
import GET_TICKET_PRICES_FOR_USER_EVENT from '../GetTicketsForUserEvent'

const useGetHasBoughtFreeTicket = (eventId: string) => {
  const { data, loading, error, empty } = useQueryResult(
    GET_TICKET_PRICES_FOR_USER_EVENT,
    {
      variables: {
        status: [
          OrderStatus.Processing,
          OrderStatus.Completed,
          OrderStatus.Captured,
        ],
        eventId,
      },
      fetchPolicy: 'cache-and-network',
      dataKey: 'Ticketing_me.orders',
    }
  )

  if (loading || empty || error) {
    return false
  }

  return data.some((order) =>
    order.orderItems.some(
      (orderItem) =>
        orderItem.ticketTier.ticketCost.type === TicketCostType.Free
    )
  )
}

export default useGetHasBoughtFreeTicket

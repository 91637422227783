import { gql } from '@apollo/client'

const GET_TICKETING_EVENT_TICKETS_LISTING = gql`
  query GET_TICKETING_EVENT_TICKETS_LISTING(
    $eventId: ID!
    $promoCodes: [String!]
  ) {
    event(id: $eventId) {
      id
      title
      ticketing {
        id
        ticketChains {
          id
          ticketTiers: ticketTiersV2(promoCodes: $promoCodes) {
            id
            title
            approvalStatus
            inactivityReason
            ticketSalesPeriod {
              startDate
              endDate
            }
            ticketAllocation {
              available
              allocation
              sold
            }
            ticketCost(promoCodes: $promoCodes) {
              displayPrice
              displayFee
              displayPromoPrice
              displayPromoFee
              type
            }
            availableOrderQuantity {
              maxPerTier
              minPerTier
            }
            currency {
              code
            }
            availableForPurchase
            saleStatus {
              name
              soldOut
              hidden
            }
          }
        }
      }
    }
  }
`

export default GET_TICKETING_EVENT_TICKETS_LISTING

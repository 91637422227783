import { toDateFormat } from 'lib/dateFormat'
import getEventListingDates from '@resident-advisor/domain-logic/get-event-listing-dates'

const isMultiDayEvent = (startTime: Date | string, endTime: Date | string) => {
  const startFormatted = toDateFormat(startTime)
  const endFormatted = toDateFormat(endTime)

  return !!(
    startFormatted &&
    endFormatted &&
    getEventListingDates(startFormatted, endFormatted).length > 1
  )
}

export default isMultiDayEvent

import { gql } from '@apollo/client'

const GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS = gql`
  query GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS {
    ticketNotificationsSubscriptions {
      userId
      eventId
    }
  }
`

export default GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS

import PropTypes from 'prop-types'
import { useConvertToZonedTime } from '@/context/TimeZoneContext'
import Date from './Date'

const TimeZoneFormattedDate = ({ date, ...props }) => {
  const convertToZonedTime = useConvertToZonedTime()

  const timeZoneFormattedDate = convertToZonedTime(date)

  return <Date date={timeZoneFormattedDate} {...props} />
}

TimeZoneFormattedDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

export default TimeZoneFormattedDate

import { gql } from '@apollo/client'

const UPDATE_BASKET = gql`
  mutation UPDATE_BASKET(
    $where: Ticketing_BasketWhereUniqueInput!
    $data: Ticketing_UpdateBasketInput!
  ) {
    Ticketing_updateBasket(where: $where, data: $data) {
      id
      total
      expiresAt
    }
  }
`

export default UPDATE_BASKET

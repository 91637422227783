export enum TicketTierSalesStatus {
  Cancelled = 'Cancelled',
  CurrentlyOnSale = 'CurrentlyOnSale',
  Deleted = 'Deleted',
  Error = 'Error',
  Pending = 'Pending',
  PendingDependencySale = 'PendingDependencySale',
  PendingOnSale = 'PendingOnSale',
  SaleEnd = 'SaleEnd',
  SaleStopped = 'SaleStopped',
  SoldOut = 'SoldOut',
}

export default TicketTierSalesStatus

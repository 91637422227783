import { Box, Text, TicketTier } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { Dispatch, SetStateAction } from 'react'
import { TicketTierDto } from '@/interfaces/ticketing-gql/TicketTierDto'
import getTicketTitle from '@/components/pro/pages/tickets/helpers/getTicketTitle'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import useNormalizedCurrency from '@/hooks/useNormalizedCurrency'
import TicketTierSalesStatus from '@/enums/ticketing-service/ticket-tier-sales-status'

interface TicketTierWrapperProps {
  ticketTier: TicketTierDto
  isSelected: boolean
  setActiveTicketTier: Dispatch<SetStateAction<TicketTierDto>>
}
const TicketTierWrapper = ({
  ticketTier,
  isSelected,
  setActiveTicketTier,
}: TicketTierWrapperProps) => {
  const {
    title,
    ticketCost: {
      displayPrice,
      displayFee,
      displayPromoPrice,
      displayPromoFee,
    },
    currency: { code },
    availableForPurchase,
    saleStatus: { name, hidden, soldOut },
  } = ticketTier
  const intl = useIntl()
  const ticketTierTitle = getTicketTitle(title, intl)
  const selectThisTicketTier = () => {
    if (availableForPurchase && !soldOut) {
      setActiveTicketTier(ticketTier)
    }
  }
  const strikethroughStatuses = [
    TicketTierSalesStatus.Cancelled,
    TicketTierSalesStatus.SaleStopped,
    TicketTierSalesStatus.SoldOut,
    TicketTierSalesStatus.SaleEnd,
  ]
  const disabledStatuses = [
    TicketTierSalesStatus.PendingOnSale,
    TicketTierSalesStatus.PendingDependencySale,
    ...strikethroughStatuses,
  ]

  const hasDiscountPromo = !!displayPromoPrice
  const formatPrice = useNormalizedCurrency(
    hasDiscountPromo ? displayPromoPrice : displayPrice,
    code,
    {
      shorten: false,
    }
  )

  const fee = hasDiscountPromo ? displayPromoFee : displayFee

  return (
    <TicketTier
      as="button"
      onClick={selectThisTicketTier}
      disabled={disabledStatuses.includes(name)}
      strikethrough={strikethroughStatuses.includes(name)}
      hasPromo={hasDiscountPromo || hidden}
      selected={isSelected}
      mb={2}
    >
      <Text>{ticketTierTitle}</Text>
      <Box css={{ whiteSpace: 'nowrap' }} data-testid="ticket-tier-price">
        <Text data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketTierPrice}>
          {formatPrice}
        </Text>
        {fee ? <Fee fee={fee} code={code} /> : null}
      </Box>
    </TicketTier>
  )
}
const Fee = ({ fee, code }: { fee: number; code: string }) => {
  const formatFee = useNormalizedCurrency(fee, code, { shorten: false })
  return (
    <>
      <Text>&nbsp;+&nbsp;</Text>
      <Text data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketTierFee}>
        {formatFee}
      </Text>
    </>
  )
}
export default TicketTierWrapper

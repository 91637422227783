import { gql } from '@apollo/client'

const SUBSCRIBE_TO_TICKET_NOTIFICATIONS = gql`
  mutation SUBSCRIBE_TO_TICKET_NOTIFICATIONS($eventId: ID!) {
    createTicketNotificationsSubscription(eventId: $eventId) {
      userId
      eventId
    }
  }
`

export default SUBSCRIBE_TO_TICKET_NOTIFICATIONS

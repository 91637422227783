import { gql } from '@apollo/client'

const EVENT_TICKET_BAR_QUERY = gql`
  query EVENT_TICKET_BAR_QUERY($id: ID!) {
    event(id: $id) {
      id
      title
      venue {
        id
        name
        contentUrl
      }
      startTime
    }
  }
`

export default EVENT_TICKET_BAR_QUERY

import PropTypes from 'prop-types'
import { Meta, Text, variants } from '@resident-advisor/design-system'
import testIds from '@/enums/testIds'

const MetaText = ({ text, color = 'primary', textProps = {}, ...props }) => {
  return (
    <Meta {...props}>
      <Text
        data-testid={testIds.metaText}
        variant={variants.text.label}
        color={color}
        {...textProps}
      >
        {text}
      </Text>
    </Meta>
  )
}

MetaText.propTypes = {
  Icon: PropTypes.elementType,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  iconColor: PropTypes.string,
  color: PropTypes.string,
  textProps: PropTypes.object,
}

export default MetaText

import {
  Icon,
  Button,
  Alignment,
  Box,
  TicketIcon,
  PlusIcon,
} from '@resident-advisor/design-system'
import React from 'react'
import { useIntl } from 'react-intl'
import testIds from '@/enums/testIds'
import eventsMessages from '@/messages/events'
import BUY_TICKET_BUTTON_TYPE, {
  BuyTicketButtonType,
} from '@/enums/ticketing-service/buy-ticket-button-type'

const BuyTicketsButton = ({
  onClick,
  buttonType,
}: {
  onClick: () => void
  buttonType: BuyTicketButtonType
}) => {
  const intl = useIntl()

  return (
    <Button
      onClick={onClick}
      width={{ s: '100%', m: 'auto' }}
      data-testid={testIds.buyTicketsButton}
    >
      <Box px={4}>
        <Alignment
          style={{ gap: '5px' }}
          alignItems="center"
          display={{ s: 'none', m: 'flex' }}
        >
          <Icon Component={TicketIcon} />
          {buttonType === BUY_TICKET_BUTTON_TYPE.free
            ? intl.formatMessage(eventsMessages.getTicket)
            : intl.formatMessage(eventsMessages.buyTickets)}
        </Alignment>
        <Alignment
          style={{ gap: '12px' }}
          alignItems="center"
          display={{ s: 'flex', m: 'none' }}
        >
          {buttonType === BUY_TICKET_BUTTON_TYPE.free ? (
            <Icon Component={TicketIcon} />
          ) : (
            <Icon size={9} Component={PlusIcon} />
          )}
          {buttonType === BUY_TICKET_BUTTON_TYPE.free
            ? intl.formatMessage(eventsMessages.getTicket)
            : intl.formatMessage(eventsMessages.choosePayment)}
        </Alignment>
      </Box>
    </Button>
  )
}

export default BuyTicketsButton

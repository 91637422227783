const BUY_TICKET_BUTTON_TYPE = Object.freeze({
  default: 'DEFAULT',
  free: 'FREE',
})

type BuyTicketButtonType =
  typeof BUY_TICKET_BUTTON_TYPE[keyof typeof BUY_TICKET_BUTTON_TYPE]

export type { BuyTicketButtonType }
export default BUY_TICKET_BUTTON_TYPE

import { domToReact } from 'html-react-parser'
import InstagramEmbed from 'react-instagram-embed'
import { Box, Image } from '@resident-advisor/design-system'
import getConfig from 'next/config'
import Link from '@/components/generic/link'
import Script from './Script'
import ResponsiveIframe from './ResponsiveIframe'
import TwitterEmbed from './TwitterEmbed'
import IframeEmbed from './IframeEmbed'

const { publicRuntimeConfig } = getConfig()

const replace = (node, textVariant) => {
  if (node.type === 'tag') {
    // eslint-disable-next-line no-param-reassign
    node.name = node.name.replace(/[><]/g, '')
  }

  if (node.type === 'tag' && node.name === 'a') {
    return (
      // eslint compains that href could be null, but we want to prioritise being null safe here
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        href={node?.attribs?.href}
        target={node?.attribs?.target}
        variant={textVariant}
        underline
      >
        {domToReact(node.children, {
          replace,
        })}
      </Link>
    )
  }

  if (node.type === 'tag' && node.name === 'img') {
    return <Image {...node.attribs} url={node.attribs.src} useLazy={false} />
  }

  if (isSocialIframeEmbed(node, socialEmbeds.YOUTUBE)) {
    return <ResponsiveIframe src={node.attribs.src} />
  }

  if (
    isSocialIframeEmbed(node, socialEmbeds.SOUNDCLOUD) ||
    isSocialIframeEmbed(node, socialEmbeds.MIXCLOUD) ||
    isSocialIframeEmbed(node, socialEmbeds.FACEBOOK)
  ) {
    return <IframeEmbed src={node.attribs.src} height={node.attribs.height} />
  }

  if (isSocialDivEmbed(node, socialEmbeds.FACEBOOK)) {
    const uri = encodeURIComponent(node.attribs.cite)
    const src = `${publicRuntimeConfig.FB_POST_PLUGIN_URL}?href=${uri}`
    return <ResponsiveIframe src={src} />
  }

  if (isSocialIframeEmbed(node, socialEmbeds.BANDCAMP)) {
    const iframeStyles = node.attribs.style
      ?.split(';')
      .reduce(embeddedStylesToObjectReducer, {})

    return <IframeEmbed src={node.attribs.src} height={iframeStyles?.height} />
  }

  if (isInstagramEmbed(node)) {
    const instagramUrl = node.attribs['data-instgrm-permalink']
    const clientAccessToken = `${publicRuntimeConfig.FB_APP_ID}|${publicRuntimeConfig.FB_CLIENT_TOKEN}`
    return (
      <InstagramEmbed
        url={instagramUrl}
        containerTagName="div"
        clientAccessToken={clientAccessToken}
      />
    )
  }

  if (isTwitterEmbed(node)) {
    const twitterLink = node.children.find(
      (child) => child.type === 'tag' && child.name === 'a'
    )

    if (twitterLink?.attribs?.href) {
      return <TwitterEmbed href={twitterLink.attribs.href} />
    }
  }

  if (node.type === 'script' && node.name === 'script') {
    return <Script {...node.attribs} />
  }

  if (node.type === 'tag' && node.name === 'blockquote') {
    return (
      <Box p={3}>
        {domToReact(node.children, {
          replace,
        })}
      </Box>
    )
  }

  return node
}

const socialEmbeds = {
  FACEBOOK: 'facebook',
  SOUNDCLOUD: 'soundcloud',
  YOUTUBE: 'youtube',
  BANDCAMP: 'bandcamp',
  MIXCLOUD: 'mixcloud',
}

const isSocialIframeEmbed = (node, srcMatch) =>
  node.type === 'tag' &&
  node.name === 'iframe' &&
  node.attribs.src?.includes(srcMatch)

const isSocialDivEmbed = (node, srcMatch) =>
  node.type === 'tag' &&
  node.name === 'blockquote' &&
  node.attribs.cite?.includes(srcMatch)

const isInstagramEmbed = (node) =>
  node.type === 'tag' &&
  node.name === 'blockquote' &&
  node.attribs['data-instgrm-permalink']

const isTwitterEmbed = (node) =>
  node.type === 'tag' &&
  node.name === 'blockquote' &&
  node.attribs.class === 'twitter-tweet'

const embeddedStylesToObjectReducer = (styles, currentStyle) => {
  const style = currentStyle.split(':')
  if (style.length !== 2 || !style[0] || !style[1]) {
    return styles
  }

  const trimmedKey = style[0].trim()
  const trimmedValue = style[1].trim()

  if (!trimmedKey || !trimmedValue) {
    return styles
  }

  return {
    ...styles,
    [trimmedKey]: trimmedValue,
  }
}

export default replace

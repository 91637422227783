import { memo, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import getConfig from 'next/config'
import {
  Box,
  Alignment,
  Button,
  Text,
  variants,
} from '@resident-advisor/design-system'
import styled from 'styled-components'
import testIds from '@/enums/testIds'
import Iframe from '@/components/shared/iframe'
import { useUserContext } from '@/context/UserContext'
import LoginModal from '@/components/auth/modals/login/LoginModal'

const {
  publicRuntimeConfig: { CHRISTMAS_PARTY },
} = getConfig()

const { URL, EVENT_ID } = CHRISTMAS_PARTY ?? {}

const EntireTicketsDemoIframe: React.FC<EntireTicketsDemoIframeProps> = memo(
  ({ id }) => {
    const [showLoginModal, setShowLoginModal] = useState(false)
    const { isAuthenticated } = useUserContext()

    return (
      <Box position="relative">
        <Iframe
          dataTestId={`${testIds.entireDemoTickets}-${id}`}
          title="Entire Tickets Demo"
          id={`#tickets-iframe-${id}`}
          url={`${URL}/event/${EVENT_ID}/tickets`}
        />
        {!isAuthenticated && (
          <Overlay
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            borderRadius="small"
          >
            <Alignment flexDirection="column" alignItems="center">
              <Text variant={variants.text.heading.m}>
                Please login to get your ticket
              </Text>
              <Box>
                <Button mt={2} onClick={() => setShowLoginModal((x) => !x)}>
                  Login
                </Button>
              </Box>
            </Alignment>
          </Overlay>
        )}
        {showLoginModal && (
          <LoginModal toggleShow={() => setShowLoginModal((x) => !x)} />
        )}
      </Box>
    )
  }
)

const Overlay = styled(Alignment)`
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.3);
`

const EntireTicketsDemoIframeProps = {
  id: PropTypes.string.isRequired,
}

EntireTicketsDemoIframe.propTypes = EntireTicketsDemoIframeProps
type EntireTicketsDemoIframeProps = InferProps<
  typeof EntireTicketsDemoIframeProps
>

export default EntireTicketsDemoIframe

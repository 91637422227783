import { useRef, useCallback, useState, useEffect } from 'react'
import { iframeResizer } from 'iframe-resizer'
import { logServerSideTransaction, LogType } from '@/server/modules/logging'

const useIframe = (path: string, skipLogging?: boolean) => {
  const ref = useRef(null)
  const [isLoading, setLoading] = useState(true)

  const pathWithoutFragment = path.split('#')[0]

  const onLoad = useCallback(() => {
    ref?.current?.iFrameResizer?.resize()

    if (isLoading) {
      setLoading(false)
    }
  }, [setLoading, isLoading])

  useEffect(() => {
    iframeResizer({ log: false, autoResize: true }, ref?.current)

    // check if iframe has already loaded
    if (isLoading && ref?.current?.contentDocument?.readyState === 'complete') {
      ref?.current?.iFrameResizer?.resize()
    }
  }, [isLoading])

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      // this message gets fired by the iframed page using GTM
      if (event.origin !== window.origin) {
        return
      }
      if (event.data?.path && !skipLogging) {
        if (pathWithoutFragment === event.data.path) {
          logServerSideTransaction({
            logType: LogType.Success,
            actionTypes: ['iframe', 'iframe.urlmatch'],
            message:
              'Success matches path requested: {urlRequested}, path rendered: {urlRendered}',
            attributes: [path, event.data.path],
          })
        } else {
          logServerSideTransaction({
            logType: LogType.SystemError,
            actionTypes: ['iframe', 'iframe.urlmatch'],
            message:
              'Failed to match path requested: {urlRequested}, path rendered: {urlRendered}',
            attributes: [path, event.data.path],
          })
        }
      }
    }
    window.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [path, pathWithoutFragment])

  return { isLoading, onLoad, ref }
}

export default useIframe

import { useMutation } from '@apollo/client'
import { TRACKING_EVENT } from 'scripts/tracking'
import Tracking from 'scripts/tracking/Tracking'
import TicketNotificationsSubscriptionDto from '@/interfaces/gql/TicketNotificationsSubscriptionDto'
import featureSwitches from '@/enums/feature-switches'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import { useQueryResult } from '@/components/generic/query-result-handler/useQueryResult'
import TOGGLE_EVENT_INTEREST from '../interest-button/ToggleEventInterestQuery'
import getOptimisticResponse from './gql/getOptimisticResponse'
import GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS from './gql/GetTicketNotificationsSubscriptionsQuery'
import SUBSCRIBE_TO_TICKET_NOTIFICATIONS from './gql/SubscribeToTicketNotificationsMutation'
import UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS from './gql/UnsubscribeFromTicketNotifications'

const useTicketNotificationsSubscriptionData = (
  isAuthenticated: boolean,
  eventId: string,
  userId: string,
  promoterIds: (string | number)[]
) => {
  const showEventInterest = useFeatureSwitch(featureSwitches.showEventInterest)
  const queryResult = useQueryResult(GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS, {
    dataKey: 'ticketNotificationsSubscriptions',
    skip: !isAuthenticated,
  })

  const [subscribe] = useMutation(SUBSCRIBE_TO_TICKET_NOTIFICATIONS, {
    variables: { eventId },
    optimisticResponse: getOptimisticResponse(
      eventId,
      userId,
      SUBSCRIBE_TO_TICKET_NOTIFICATIONS
    ),
    update: (cache, { data }) => {
      if (data?.createTicketNotificationsSubscription?.eventId) {
        const previousSubscriptions =
          cache.readQuery<TicketNotificationsSubscriptionsData>({
            query: GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS,
          })

        if (previousSubscriptions?.ticketNotificationsSubscriptions) {
          cache.writeQuery({
            query: GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS,
            data: {
              ticketNotificationsSubscriptions: [
                ...previousSubscriptions.ticketNotificationsSubscriptions,
                data?.createTicketNotificationsSubscription,
              ],
            },
          })
        }
      }
    },
  })

  const [unsubscribe] = useMutation(UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS, {
    variables: { eventId },
    optimisticResponse: getOptimisticResponse(
      eventId,
      userId,
      UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS
    ),
    update: (cache, { data }) => {
      if (data?.deleteTicketNotificationsSubscription?.eventId) {
        const previousSubscriptions =
          cache.readQuery<TicketNotificationsSubscriptionsData>({
            query: GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS,
          })

        if (previousSubscriptions?.ticketNotificationsSubscriptions) {
          cache.writeQuery({
            query: GET_TICKET_NOTIFICATIONS_SUBSCRIPTIONS,
            data: {
              ticketNotificationsSubscriptions:
                previousSubscriptions.ticketNotificationsSubscriptions.filter(
                  (subscription) =>
                    subscription.eventId.toString() !==
                    data.deleteTicketNotificationsSubscription.eventId.toString()
                ),
            },
          })
        }
      }
    },
  })

  const [toggleEventInterest] = useMutation(TOGGLE_EVENT_INTEREST, {
    variables: {
      eventId,
      shouldShowInterest: true,
    },
  })

  const isSubscribed = !!queryResult.data?.find(
    (ticketNotificationsSubscription) =>
      ticketNotificationsSubscription?.eventId.toString() === eventId.toString()
  )

  const toggleSubscription = () => {
    const trackingProperties = {
      'Event ID': eventId,
      'Promoter IDs': promoterIds,
    }

    if (isSubscribed) {
      unsubscribe()

      Tracking.trackMixpanel(
        TRACKING_EVENT.unsubscribedFromTicketNotifications,
        trackingProperties
      )
    } else {
      subscribe()
      if (!showEventInterest) {
        toggleEventInterest()
      }

      Tracking.trackMixpanel(
        TRACKING_EVENT.subscribedToTicketNotifications,
        trackingProperties
      )
    }
  }

  return { ...queryResult, isSubscribed, toggleSubscription }
}

type TicketNotificationsSubscriptionsData = {
  ticketNotificationsSubscriptions: TicketNotificationsSubscriptionDto[]
}

export default useTicketNotificationsSubscriptionData

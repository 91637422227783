import { ApolloError, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { logTransaction, LogType } from 'lib/transactionalLogger'
import { useEffect } from 'react'
import TicketCostType from '@/enums/ticketing-service/ticket-cost-type'
import useGetFreeTicket from '@/components/events/pages/payment/payment-method/hooks/useGetFreeTicket'
import { BasketDto } from '@/interfaces/ticketing-gql/BasketDto'
import { TicketTierDto } from '@/interfaces/ticketing-gql/TicketTierDto'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useUserContext } from '@/context/UserContext'
import GET_BASKET from '@/components/events/pages/payment/gql/GetBasketQuery'
import { useQueryResult } from '@/components/generic/query-result-handler'
import CREATE_BASKET from './CreateBasketQuery'
import UPDATE_BASKET from './UpdateBasketQuery'

const useCreateOrUpdateBasket = ({
  activeTicketTier,
  quantity,
  eventId,
  promoCode,
  onError,
  setIsProcessingFreeTicket,
}: {
  activeTicketTier: TicketTierDto
  quantity: number
  eventId: string
  promoCode?: string
  onError?: (error: ApolloError) => void
  setIsProcessingFreeTicket?: (isProcessing: boolean) => void
}) => {
  const { push } = useRouter()

  const { id: activeTicketTierId, currency } = activeTicketTier ?? {}

  const { isAuthenticated } = useUserContext()
  const {
    localValue: basketId,
    setLocalStorageValue,
    clearValue,
  } = useLocalStorage('basketId', undefined)

  const isFreeTicket =
    activeTicketTier?.ticketCost?.type === TicketCostType.Free

  useEffect(() => {
    if (isFreeTicket && setIsProcessingFreeTicket) {
      setIsProcessingFreeTicket(true)
    }
  }, [isFreeTicket, setIsProcessingFreeTicket])

  const { handleGetFreeTicket } = useGetFreeTicket(eventId)

  const handlePaymentPage = (id: string) => {
    if (!isAuthenticated) {
      push(`/payment/${id}/account`)
    } else if (isFreeTicket) {
      handleGetFreeTicket(id)
    } else {
      push(`/payment/${id}`)
    }
  }

  const [createBasketMutation] = useMutation(CREATE_BASKET, {
    onError,
    onCompleted: async (response) => {
      const { id } = response?.Ticketing_createBasketV2 ?? {}
      setLocalStorageValue(id)
      handlePaymentPage(id)
    },
  })

  const [updateBasketMutation] = useMutation(UPDATE_BASKET, {
    refetchQueries: [
      {
        query: GET_BASKET,
        variables: {
          where: {
            id: basketId,
          },
        },
      },
    ],
    onError: (error) => {
      // Clear basket from local storage so that a new basket is created next time
      clearValue()
      onError?.(error)
    },
    onCompleted: () => {
      handlePaymentPage(basketId)
    },
  })

  const { data, empty, error } = useQueryResult<BasketDto>(GET_BASKET, {
    variables: {
      where: {
        id: basketId,
      },
    },
    dataKey: 'Ticketing_basket',
    onCompleted: () => {
      logTransaction({
        logType: LogType.Success,
        actionTypes: ['basket', 'basket.get-basket'],
        message: 'Success getting basket',
      })
    },
    onError: (err) => {
      logTransaction({
        logType: LogType.SystemError,
        actionTypes: ['basket', 'basket.get-basket'],
        message: 'Error getting basket',
        attributes: [err.message],
      })
    },
    skip: !basketId,
  })

  const createOrUpdateBasket = async () => {
    const promoCodes = promoCode ? [promoCode] : []

    if (
      !basketId ||
      error ||
      empty ||
      // If the basket contains items with different currencies, create a new basket
      (currency?.code &&
        data?.basketItems?.some(
          (item) => item.ticketTier.currency?.code !== currency.code
        )) ||
      // if active ticket tier is free or the basket contains a free ticket create a new basket
      isFreeTicket ||
      data?.basketItems?.some(
        (item) => item.ticketCost?.type === TicketCostType.Free
      )
    ) {
      await createBasketMutation({
        variables: {
          data: {
            basketItems: [
              {
                quantity,
                ticketTierId: activeTicketTierId,
              },
            ],
            promoCodes,
          },
        },
      })
    } else {
      await updateBasketMutation({
        variables: {
          where: {
            id: basketId,
          },
          data: {
            basketItems: [{ ticketTierId: activeTicketTierId, quantity }],
            promoCodes,
          },
        },
      })
    }
  }

  return createOrUpdateBasket
}

export default useCreateOrUpdateBasket

import { gql } from '@apollo/client'

const UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS = gql`
  mutation UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS($eventId: ID!) {
    deleteTicketNotificationsSubscription(eventId: $eventId) {
      userId
      eventId
    }
  }
`

export default UNSUBSCRIBE_FROM_TICKET_NOTIFICATIONS

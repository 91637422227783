import PropTypes from 'prop-types'
import { SubSection, Box, variants } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
import { padding } from '@/themes'
import messages from '@/messages/events'
import TICKETING_SYSTEM from '@/enums/ticketing-system'
import TicketNotifications from '@/components/events/pages/event-detail/ticket-notifications'
import {
  PerformanceTrackingTypes,
  useManualPerformanceTracking,
} from '@/context/PerformanceContext/usePerformanceTracking'
import useIsMobile from '@/hooks/useIsMobile'
import TicketsIframe from './TicketsIframe'
import EventTicketBar from './event-ticket-bar'
import TicketsListing from './TicketsListing/TicketsListing'
import useMultiStageCheckoutRedirectUrl from './useMultiStageCheckoutRedirectUrl'

// Dynamically importing this so auto-showing for mobile works
// as it needs to work out the height from the client
const TicketsModal = dynamic(() => import('./TicketsModal'), { ssr: false })
TicketsModal.displayName = 'TicketsModal'

const TICKET_PANEL_ID = 'tickets'

const EventTickets = ({ event }) => {
  const [ref, inView] = useInView()
  const intl = useIntl()
  const isMobile = useIsMobile()
  // We should skip tracking if the device is mobile in this case
  // since the iframe is not loaded on mobile when the user lands on the page
  const onLoad = useManualPerformanceTracking(
    PerformanceTrackingTypes.LoadEventDetailsTicketed,
    'ticketsIframe',
    isMobile
  )

  const isNewAPITickets = event.ticketingSystem === TICKETING_SYSTEM.entire
  const redirectUrl = useMultiStageCheckoutRedirectUrl(event.id)

  return (
    <>
      <Box display={{ s: 'none', m: 'block' }}>
        <SubSection
          title={
            isNewAPITickets ? null : intl.formatMessage(messages.raTickets)
          }
          py={padding.pt}
          variant={variants.section.translucent}
        >
          <div id={TICKET_PANEL_ID} ref={ref}>
            <TicketNotifications event={event} />
            {isNewAPITickets ? (
              <TicketsListing eventId={event.id} />
            ) : (
              <TicketsIframe
                event={event}
                redirectUrl={redirectUrl}
                id="m"
                onLoad={onLoad}
              />
            )}
          </div>
        </SubSection>
        <motion.div initial="hidden" animate={inView ? 'hidden' : 'visible'}>
          <EventTicketBar
            eventId={event.id}
            ticketPanelId={`#${TICKET_PANEL_ID}`}
          />
        </motion.div>
      </Box>

      <Box display={{ s: 'block', m: 'none' }}>
        <TicketsModal event={event} ticketPanelId={`#${TICKET_PANEL_ID}`} />
      </Box>
    </>
  )
}

EventTickets.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    promoters: PropTypes.arrayOf(PropTypes.object).isRequired,
    canSubscribeToTicketNotifications: PropTypes.bool.isRequired,
    ticketingSystem: PropTypes.oneOf(Object.values(TICKETING_SYSTEM)),
    ticketing: PropTypes.shape({
      id: PropTypes.string,
      legacyId: PropTypes.string,
      title: PropTypes.string,
      ticketTiers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          ticketAllocation: PropTypes.shape({
            allocation: PropTypes.number,
            available: PropTypes.number,
            sold: PropTypes.number,
          }),
        })
      ),
    }),
    userId: PropTypes.string,
  }),
}

export default EventTickets

import { logger } from 'lib/logger'

type ServerSideTransactionLog = {
  message: string
  logType: LogType
  actionTypes: Array<string>
  errorDependency?: string
  err?: Error
  [key: string]: unknown
}

enum LogType {
  Success = 0,
  UserError = 1,
  SystemError = 2,
}

const logTypeToTextMap = {
  [LogType.Success]: 'Success',
  [LogType.UserError]: 'UserError',
  [LogType.SystemError]: 'SystemError',
}

const mapLogTypeToText = (logType: LogType) => {
  // converting log type enum to string ra-web understands
  if (logType in logTypeToTextMap) {
    return logTypeToTextMap[logType]
  }

  return 'Unknown'
}

const logServerSideTransaction = (log: ServerSideTransactionLog) => {
  const { logType, message, ...rest } = log
  if (logType === LogType.Success || logType === LogType.UserError) {
    logger.info(
      {
        ...rest,
        DataDogForceIndex: true,
        logType: mapLogTypeToText(logType),
      },
      message
    )
  } else {
    logger.error(
      {
        ...rest,
        DataDogForceIndex: true,
        logType: mapLogTypeToText(logType),
      },
      message
    )
  }
}

export { logServerSideTransaction, LogType }
export type { ServerSideTransactionLog }

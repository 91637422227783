import { NextApiRequest, NextApiResponse } from 'next'
import { logger } from 'lib/logger'

const logRoute = (req: NextApiRequest, res: NextApiResponse) => {
  let { body } = req

  if (!body) {
    body = {}
  }

  const level = body.level ?? 'error'

  const bindings = {
    fromNextApi: true,
    url: body.url,
  } as Record<string, unknown>

  if (Array.isArray(body.logEvent?.bindings)) {
    body.logEvent.bindings.map((item) => Object.assign(bindings, item))
  }

  if (bindings.isClientSideRequest) {
    bindings.actionTypes = [
      'pagerequest',
      'pagerequest.ra-next',
      'pagerequest.ra-next.csr',
    ]
  }

  bindings.statusCode = bindings.clientSideStatusCode

  if (Array.isArray(body.logEvent?.messages)) {
    logger[level](bindings, ...body.logEvent.messages)
  } else {
    // Maintain backwards compatibility if clients are sending the old 'message' property
    logger[level](bindings, body.message)
  }

  res.statusCode = 200
  return res.json({ message: 'Log sent' })
}

export default logRoute

import { useMutation } from '@apollo/client'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Button,
  Icon,
  FollowIcon,
  variants,
} from '@resident-advisor/design-system'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import messages from '@/messages/dict'
import buttonTrackingIds from '@/tracking/button-tracking-ids'
import { useUserContext } from '@/context/UserContext'
import LoginModal from '@/components/auth/modals/login/LoginModal'
import TOGGLE_EVENT_INTEREST from './ToggleEventInterestQuery'

const constrainCount = (newFollowerCount) => Math.max(0, newFollowerCount)

const InterestButton = ({ id, isInterested, interestedCount }) => {
  const intl = useIntl()
  const { user } = useUserContext()
  const [showLoginModal, setShow] = useState(false)
  const [toggle] = useMutation(TOGGLE_EVENT_INTEREST)

  const optimisticResponse = {
    __typename: 'Mutation',
    toggleEventInterest: {
      id,
      interestedCount: isInterested
        ? constrainCount(interestedCount - 1)
        : interestedCount + 1,
      isInterested: !isInterested,
      __typename: 'Event',
    },
  }

  const onShowInterest = () =>
    toggle({
      variables: { eventId: id, shouldShowInterest: !isInterested },
      optimisticResponse,
    })

  return (
    <>
      <Button
        mr={3}
        variant={variants.button.secondary}
        onClick={() => {
          if (user) return onShowInterest()
          return setShow(true)
        }}
        data-button-tracking-id={`${buttonTrackingIds.eventInterestButton}-${
          isInterested ? 'remove-interest' : 'show-interest'
        }`}
      >
        {!isInterested && <Icon flexShrink={0} Component={FollowIcon} />}
        {intl.formatMessage(messages.interested)}
      </Button>
      {showLoginModal && (
        <LoginModal
          toggleShow={() => setShow(!showLoginModal)}
          onSuccess={() => {
            onShowInterest()
            setShow(false)
          }}
          source={TRACKING_PROPERTY.loginSource.interested}
        />
      )}
    </>
  )
}

InterestButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInterested: PropTypes.bool,
  interestedCount: PropTypes.number,
}

export default InterestButton

import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { format } from 'date-fns'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {
  Box,
  Layout,
  Text,
  Alignment,
  Section,
  Icon,
  CalendarIcon,
  LocationIcon,
  TicketIcon,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'

import QueryResultHandler from '@/components/generic/query-result-handler'
import FullWidth from '@/components/generic/full-width'
import LinkButton from '@/components/generic/link-button'
import MetaText from '@/components/generic/meta-text'
import messages from '@/messages/events'
import useDimensions from '@/hooks/useDimensions'
import { useThemeContext } from '@/hooks/useThemeContext'
import animation from '@/themes/animation'
import trackingIds from '@/tracking/tracking-ids'
import useDateFnsLocale from '@/hooks/useDateFnsLocale'
import EVENT_TICKET_BAR_QUERY from './EventTicketBarQuery'

const EventTicketBar = ({ eventId, ticketPanelId }) => {
  const [stickyWrapperRef, { height }] = useDimensions()
  const theme = useThemeContext()

  const props = useQuery(EVENT_TICKET_BAR_QUERY, {
    variables: { id: eventId },
  })

  return (
    <StickyWrapper
      variants={{
        visible: {
          y: 0,
          transition: animation.default.open,
          display: 'initial',
        },
        hidden: {
          y: 100,
          transition: animation.default.closed,
          transitionEnd: { display: 'none' },
        },
      }}
      ref={stickyWrapperRef}
    >
      <Section
        variant={variants.section.secondary}
        data-tracking-id={trackingIds.eventTicketBar}
      >
        <QueryResultHandler
          {...props}
          ticketPanelId={ticketPanelId}
          dataKey="event"
          handleLoading={() => null}
          markup={EventTicketBarMarkup}
        />
      </Section>
      <style
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{
          __html: `
            @media(min-width: ${theme.breakpoints.m}) {
              body {
                padding-bottom: ${height}px;
              }
            }
          `,
        }}
      />
    </StickyWrapper>
  )
}

EventTicketBar.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ticketPanelId: PropTypes.string.isRequired,
}

const EventTicketBarMarkup = ({ data: event, ticketPanelId }) => {
  const intl = useIntl()
  const locale = useDateFnsLocale()

  const eventDate = useMemo(
    () => format(new Date(event.startTime), 'eee, d MMM', locale),
    [event.startTime, locale]
  )

  return (
    <FullWidth sizes={['s', 'm', 'l']}>
      <Layout>
        <Alignment alignItems="center" justifyContent="space-between" py={3}>
          <EventMeta
            title={event.title}
            venue={event.venue}
            eventDate={eventDate}
          />
          <LinkButton href={ticketPanelId}>
            <Icon Component={TicketIcon} mr={2} />
            {intl.formatMessage(messages.buyTickets)}
          </LinkButton>
        </Alignment>
      </Layout>
    </FullWidth>
  )
}

EventTicketBarMarkup.propTypes = {
  data: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    venue: PropTypes.object.isRequired,
  }).isRequired,
  ticketPanelId: PropTypes.string.isRequired,
}

const StickyWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  width: 100%;
  ${({ theme }) =>
    css`
      z-index: ${theme.zIndex.desktopBuyButton};
    `};
`

const EventMeta = ({ title, venue, eventDate }) => (
  <Box>
    <Text variant={variants.text.heading.s} pb={1} as="h3">
      {title}
    </Text>
    <Alignment>
      <MetaText Icon={LocationIcon} text={venue.name} />
      <Box ml={4}>
        <MetaText Icon={CalendarIcon} ml={1} text={eventDate} />
      </Box>
    </Alignment>
  </Box>
)

EventMeta.propTypes = {
  title: PropTypes.string.isRequired,
  venue: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  eventDate: PropTypes.string.isRequired,
}

export default EventTicketBar

import { gql } from '@apollo/client'

const BRUNCH_CHILD_EVENT_FRAGMENT = gql`
  fragment brunchChildEventFragment on Event {
    canSubscribeToTicketNotifications
    promoters {
      id
    }
    # Also get available ticket tiers excluding add-ons for other functionality e.g. ticket notifications
    standardTickets: tickets(queryType: AVAILABLE, ticketTierType: TICKETS) {
      id
      validType
    }
  }
`

const GET_EVENT_DETAIL = gql`
  query GET_EVENT_DETAIL(
    $id: ID!
    $isAuthenticated: Boolean!
    $canAccessPresale: Boolean!
    $enableNewBrunchTicketing: Boolean! = false
  ) {
    event(id: $id) {
      id
      title
      flyerFront
      flyerBack
      content
      minimumAge
      cost
      contentUrl
      embargoDate
      date
      time
      startTime
      endTime
      interestedCount
      lineup
      isInterested
      isSaved
      isTicketed
      isFestival
      dateUpdated
      resaleActive
      newEventForm
      datePosted
      hasSecretVenue
      live
      canSubscribeToTicketNotifications
      images {
        id
        filename
        alt
        type
        crop
      }
      venue {
        id
        name
        address
        contentUrl
        live
        area {
          id
          name
          urlName
          country {
            id
            name
            urlCode
            isoCode
          }
        }
        location {
          latitude
          longitude
        }
      }
      promoters {
        id
        name
        contentUrl
        live
        hasTicketAccess
        tracking(types: [PAGEVIEW]) {
          id
          code
          event
        }
      }
      artists {
        id
        name
        contentUrl
        urlSafeName
      }
      pick {
        id
        blurb
        author {
          id
          name
          imageUrl
          username
          contributor
        }
      }
      promotionalLinks {
        title
        url
      }
      tracking(types: [PAGEVIEW]) {
        id
        code
        event
      }
      admin {
        id
        username
      }
      # Get all available ticket tiers
      tickets(queryType: AVAILABLE) {
        id
        title
        validType
        onSaleFrom
        priceRetail
        isAddOn
        currency {
          id
          code
        }
      }
      # Also get available ticket tiers excluding add-ons for other functionality e.g. ticket notifications
      standardTickets: tickets(queryType: AVAILABLE, ticketTierType: TICKETS) {
        id
        validType
      }
      userOrders @include(if: $isAuthenticated) {
        id
        rAOrderNumber
      }
      playerLinks {
        id
        sourceId
        audioService {
          id
          name
        }
      }
      childEvents {
        id
        date
        isTicketed
        ...brunchChildEventFragment @include(if: $enableNewBrunchTicketing)
      }
      genres {
        id
        name
        slug
      }
      setTimes {
        id
        lineup
        status
      }
      area {
        ianaTimeZone
      }
      presaleStatus
      isSignedUpToPresale @include(if: $canAccessPresale)
      ticketingSystem
    }
  }
  ${BRUNCH_CHILD_EVENT_FRAGMENT}
`

export default GET_EVENT_DETAIL

import React, { useEffect, useState } from 'react'
import {
  Alignment,
  Counter,
  variants,
  Text,
  Box,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { ApolloError } from '@apollo/client'
import arrayHasData from 'lib/arrayHasData'
import { useRouter } from 'next/router'
import eventsMessages from '@/messages/events'
import BuyTicketsButton from './BuyTicketsButton'
import TicketTierWrapper from './TicketTier'
import useCreateOrUpdateBasket from './useCreateOrUpdateBasket'
import GET_TICKETING_EVENT_TICKETS_LISTING from '../GetTicketingEventTicketsListingQuery'
import FreeTicketsInfoPanel from './FreeTicketsInfoPanel'
import useGetHasBoughtFreeTicket from './useGetHasBoughtFreeTicket'
import useTicketsPromoCode from '../useTicketsPromoCode'
import BasketErrorPanel from './BasketErrorPanel'
import TICKETING_SYSTEM from '@/enums/ticketing-system'
import {
  DefaultLoadingSpinner,
  useQueryResult,
} from '@/components/generic/query-result-handler'
import EventDto from '@/interfaces/gql/EventDto'
import BUY_TICKET_BUTTON_TYPE from '@/enums/ticketing-service/buy-ticket-button-type'
import TicketCostType from '@/enums/ticketing-service/ticket-cost-type'
import TicketTierApprovalStatus from '@/enums/ticketing-service/ticket-tier-approval-status'
import { getLink } from '@/messages/formatters'
import sortTicketChainsByStartDate from './sortTicketChainsByStartDate'
import { TicketTierDto } from '@/interfaces/ticketing-gql/TicketTierDto'

const TicketsListing = ({ eventId }: { eventId: string }) => {
  const promoCode = useTicketsPromoCode({
    ticketingSystem: TICKETING_SYSTEM.entire,
  })

  const { data, loading, error, empty } = useQueryResult(
    GET_TICKETING_EVENT_TICKETS_LISTING,
    {
      variables: {
        eventId,
        promoCodes: promoCode ? [promoCode] : undefined,
      },
      dataKey: 'event',
    }
  )

  if (loading || empty || error) {
    return null
  }

  return <TicketsListingMarkup data={data} promoCode={promoCode} />
}

const TicketsListingMarkup = ({
  data,
  promoCode,
}: {
  data: EventDto
  promoCode?: string
}) => {
  const intl = useIntl()
  const {
    asPath,
    query: { freeticket: freeTicketId },
  } = useRouter()

  const [basketError, setBasketError] = useState<string>(null)

  const ticketChains = data.ticketing.ticketChains.filter((tc) =>
    arrayHasData(tc.ticketTiers)
  )

  const ticketTiers: TicketTierDto[] = sortTicketChainsByStartDate(
    ticketChains
  )?.flatMap((chain) => chain.ticketTiers)

  const hasBoughtFreeTicket = useGetHasBoughtFreeTicket(data.id)

  const isFreeTicketAvailable = ticketTiers.some(
    (ticket) => ticket.ticketCost.type === TicketCostType.Free
  )

  const isEventPurchasable = ticketTiers.some(
    (ticket) => ticket.availableForPurchase
  )

  const showHiddenTicketsView =
    promoCode && ticketTiers.some((tt) => tt.saleStatus.hidden)

  const availableTicketTiers = ticketTiers.filter(
    ({ saleStatus: { hidden, soldOut }, availableForPurchase }) =>
      !soldOut &&
      availableForPurchase &&
      (promoCode && ticketTiers.some((tt) => tt.saleStatus.hidden)
        ? hidden
        : !hidden)
  )
  const firstPurchasableTicketTier = availableTicketTiers?.at(0)

  const purchaseableFreeTicketTierOnLoginRedirect = availableTicketTiers?.find(
    ({ id }) => id === freeTicketId
  )

  const [processingFreeTicket, setIsProcessingFreeTicket] = useState(false)

  const [activeTicketTier, setActiveTicketTier] = useState(
    firstPurchasableTicketTier
  )

  const [quantity, setQuantity] = useState(
    firstPurchasableTicketTier?.availableOrderQuantity?.minPerTier || 1
  )

  useEffect(() => {
    setQuantity(activeTicketTier?.availableOrderQuantity?.minPerTier || 1)
  }, [activeTicketTier])

  const handleBasket = useCreateOrUpdateBasket({
    activeTicketTier,
    quantity,
    eventId: data.id,
    promoCode:
      activeTicketTier?.ticketCost?.displayPromoPrice ||
      activeTicketTier?.saleStatus?.hidden
        ? promoCode
        : undefined,
    onError: (e: ApolloError) => {
      setBasketError(e.message)
    },
    setIsProcessingFreeTicket,
  })
  // handle free tickets on login redirect
  const shouldGetFreeTicketOnRedirect =
    isEventPurchasable &&
    isFreeTicketAvailable &&
    !!purchaseableFreeTicketTierOnLoginRedirect
  useEffect(() => {
    if (freeTicketId && !processingFreeTicket) {
      if (shouldGetFreeTicketOnRedirect) {
        setActiveTicketTier(purchaseableFreeTicketTierOnLoginRedirect)
        handleBasket()
      } else {
        // we need to do this to avoid infinite loop
        // router.push() or reload() doesn't trigger a revalidation
        window.history.replaceState(
          {},
          '',
          asPath.replace(`?freeticket=${freeTicketId}`, '')
        )
      }
    }
  }, [
    purchaseableFreeTicketTierOnLoginRedirect,
    handleBasket,
    processingFreeTicket,
    shouldGetFreeTicketOnRedirect,
    freeTicketId,
    data.id,
    asPath,
  ])

  return (
    <Box
      display={{ s: 'flex', m: 'grid' }}
      flexDirection="column"
      gridTemplateColumns="7fr 5fr"
      flex={1}
    >
      <Alignment flexDirection="column" justifyContent="space-between" flex={1}>
        {freeTicketId && shouldGetFreeTicketOnRedirect && (
          <DefaultLoadingSpinner />
        )}
        <Box>
          {isFreeTicketAvailable && hasBoughtFreeTicket && (
            <FreeTicketsInfoPanel />
          )}
          {basketError && <BasketErrorPanel basketError={basketError} />}
          <Box
            display={{ s: 'block', m: 'none' }}
            mb={4}
            width={{ s: '100%', m: 'auto' }}
          >
            <Text color="primary" variant={variants.text.heading.l}>
              {data.title}
            </Text>
          </Box>
          <Alignment flexDirection="column" mb={{ s: 4 }}>
            {ticketTiers
              ?.filter(
                ({
                  saleStatus: { hidden },
                  approvalStatus,
                  inactivityReason,
                }) =>
                  approvalStatus === TicketTierApprovalStatus.Approved &&
                  inactivityReason === null &&
                  showHiddenTicketsView
                    ? hidden
                    : !hidden
              )
              ?.map((ticketTier) => (
                <TicketTierWrapper
                  key={ticketTier.id}
                  ticketTier={ticketTier}
                  isSelected={activeTicketTier?.id === ticketTier.id}
                  setActiveTicketTier={setActiveTicketTier}
                />
              ))}
          </Alignment>
        </Box>
        <Box>
          {isEventPurchasable && (
            <Alignment
              flexDirection={{ s: 'column', m: 'row' }}
              justifyContent={{ m: 'space-between' }}
              alignItems={{ s: 'center' }}
              style={{ gap: '30px' }}
            >
              <Counter
                value={quantity}
                onChange={setQuantity}
                min={activeTicketTier?.availableOrderQuantity?.minPerTier}
                max={activeTicketTier?.availableOrderQuantity?.maxPerTier}
              />
              <BuyTicketsButton
                buttonType={
                  activeTicketTier?.ticketCost?.displayPrice === 0
                    ? BUY_TICKET_BUTTON_TYPE.free
                    : BUY_TICKET_BUTTON_TYPE.default
                }
                onClick={handleBasket}
              />
            </Alignment>
          )}
          <Alignment
            mb={4}
            mt={1}
            justifyContent="center"
            display={{ m: 'none' }}
          >
            <Text color="primary" variant={variants.text.small}>
              {intl.formatMessage(eventsMessages.payTerms, {
                terms: getLink({
                  href: '/purchase-policy',
                  underline: true,
                  variant: variants.text.small,
                }),
              })}
            </Text>
          </Alignment>
        </Box>
      </Alignment>
    </Box>
  )
}

export default TicketsListing

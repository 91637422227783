import { useRouter } from 'next/router'
import TICKETING_SYSTEM, { TicketingSystem } from '@/enums/ticketing-system'

const useTicketsPromoCode = ({
  promoCodeOverride,
  ticketingSystem = TICKETING_SYSTEM.legacy,
}: {
  promoCodeOverride?: string
  ticketingSystem?: TicketingSystem
}): string => {
  const {
    query: { p, promo },
  } = useRouter()

  if (promoCodeOverride) {
    return promoCodeOverride
  }

  const ticketsPromo = ticketingSystem === TICKETING_SYSTEM.entire ? promo : p

  // ra-web only accepts one promocode selecting the first if this is an array.
  // Otherwise, we only support single promo codes for now.
  if (Array.isArray(ticketsPromo)) {
    return ticketsPromo[0]
  }

  return ticketsPromo
}

export default useTicketsPromoCode

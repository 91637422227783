import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Text, Alignment, variants } from '@resident-advisor/design-system'
import { promoterByIdRoute } from 'lib/routes'
import Link from '@/components/generic/link'

const PromoterList = ({ items }) => (
  <Alignment flexDirection="column" flexWrap="wrap">
    {items.map((item, index) => {
      return (
        <Fragment key={index}>
          {item.live ? (
            <Link
              variant={variants.text.small}
              underline
              {...promoterByIdRoute(item.contentUrl)}
            >
              {item.name}
            </Link>
          ) : (
            <Text variant={variants.text.small} color="primary">
              {item.name}
            </Text>
          )}
          {index < items.length - 1 && (
            <Text variant={variants.text.small} color="primary" />
          )}
        </Fragment>
      )
    })}
  </Alignment>
)

PromoterList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ contentUrl: PropTypes.string, name: PropTypes.string })
  ),
}

export default PromoterList

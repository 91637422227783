enum OrderStatus {
  Processing = 'processing',
  Completed = 'completed',
  Captured = 'captured',
  Failed = 'failed',
  Authorized = 'authorized',
  Cancelled = 'cancelled',
}

export default OrderStatus

import { DocumentNode } from '@apollo/client'
import SUBSCRIBE_TO_TICKET_NOTIFICATIONS from './SubscribeToTicketNotificationsMutation'

const getOptimisticResponse = (
  eventId: string,
  userId: string,
  query: DocumentNode
) => {
  // These IDs are returned as numbers on the TicketNotificationSubscription object
  const expectedReturnedEventId = parseInt(eventId, 10)
  const expectedReturnedUserId = parseInt(userId, 10)

  if (query === SUBSCRIBE_TO_TICKET_NOTIFICATIONS) {
    return {
      __typename: 'Mutation',
      createTicketNotificationsSubscription: {
        __typename: 'TicketNotificationsSubscription',
        userId: expectedReturnedUserId,
        eventId: expectedReturnedEventId,
      },
    }
  }

  return {
    __typename: 'Mutation',
    deleteTicketNotificationsSubscription: {
      __typename: 'TicketNotificationsSubscription',
      userId: expectedReturnedUserId,
      eventId: expectedReturnedEventId,
    },
  }
}

export default getOptimisticResponse

import { IntlShape } from 'react-intl'
import titles from '@/messages/pre-configured-ticket-tier-titles'

/**
 * Gets the ticket title from the pre-configured ticket titles
 * For Entire tickets this will find any with the relevant translations
 * For legacy tickets they will not exist in the pre-configured titles
 * so will return the title as is
 */
const getTicketTitle = (title: string, intl: IntlShape): string => {
  return titles[title] ? intl.formatMessage(titles[title]) : title
}

export default getTicketTitle

import {
  AlertIcon,
  Box,
  InfoPanel,
  Text,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from '@/messages/payment'
import errors from '@/messages/errors'
import BasketError from '@/enums/ticketing-service/basket-error'

const BasketErrorPanel = ({ basketError }: { basketError?: string }) => {
  const intl = useIntl()

  return (
    <Box pb={{ s: 0, m: 4 }}>
      <InfoPanel icon={AlertIcon}>
        {basketError === BasketError.PromoAllocationExceeded ? (
          <Text variant={variants.text.bodyBold}>
            {intl.formatMessage(errors.promotionEnded)}
          </Text>
        ) : (
          <>
            <Text variant={variants.text.bodyBold}>
              {intl.formatMessage(messages.smthWentWrong)}
            </Text>
            <Text variant={variants.text.body}>
              {intl.formatMessage(messages.smthWentWrongText)}
            </Text>
          </>
        )}
      </InfoPanel>
    </Box>
  )
}

export default BasketErrorPanel

import { useState } from 'react'
import { usePaymentErrorContext } from '@/context/PaymentErrorsContext/PaymentErrorsContext'
import { PaymentErrorTypes } from '@/context/PaymentErrorsContext/types'
import TicketCostType from '@/enums/ticketing-service/ticket-cost-type'
import useCreateOrder from './useCreateOrder'
import usePollForFulfilmentStatus from './usePollForFulfilmentStatus'

const useGetFreeTicket = (eventId: string) => {
  const [confirmedOrderNumber, setConfirmedOrderNumber] = useState('')
  const [, setIsProcessing] = useState(false)

  const onSuccess = (orderId: string) => {
    setConfirmedOrderNumber(orderId)
  }

  usePollForFulfilmentStatus(eventId, confirmedOrderNumber, setIsProcessing)

  const { setError } = usePaymentErrorContext()

  const { handleCreateOrder, loading } = useCreateOrder()

  const handleGetFreeTicket = async (basketId: string) => {
    try {
      const orderResponse = await handleCreateOrder(
        basketId,
        TicketCostType.Free
      )
      onSuccess(orderResponse.data.Ticketing_createOrder.orderNumber)
    } catch (e) {
      setError(PaymentErrorTypes.FreeTicketOrderError)
    }
  }

  return { handleGetFreeTicket, loading }
}

export default useGetFreeTicket

import { eventsByAreaRoute, eventsByGeoLocationRoute } from 'lib/routes'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import AreaDto from '@/interfaces/gql/AreaDto'
import { usePreviousPathContext } from '@/context/PreviousPathContext'

const useEventDetailBreadcrumb = (
  area: AreaDto,
  enableNewBrunchTicketing = false
) => {
  const { previousPath, previousBrunchPath } = usePreviousPathContext()

  const { href: eventsAreaHref, as: eventsAreaAs } = eventsByAreaRoute(
    generateUrlForAreaAndCountry('/events', area)
  )
  const { as: eventsLocationAs } = eventsByGeoLocationRoute(previousPath)

  const baseAreaReturnPath =
    typeof eventsAreaAs === 'string' ? eventsAreaAs : eventsAreaAs.pathname

  const baseLocationReturnPath =
    typeof eventsLocationAs === 'string'
      ? eventsLocationAs
      : eventsLocationAs.pathname

  const isEventListings =
    previousPath?.includes(baseAreaReturnPath) ||
    previousPath?.includes(baseLocationReturnPath)

  const breadcrumbHref = isEventListings ? previousPath : eventsAreaHref
  const breadcrumbAs = isEventListings ? previousPath : eventsAreaAs

  return {
    href: enableNewBrunchTicketing ? previousBrunchPath : breadcrumbHref,
    as: enableNewBrunchTicketing ? previousBrunchPath : breadcrumbAs,
  }
}

export default useEventDetailBreadcrumb

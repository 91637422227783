const sortTicketChainsByStartDate = (unsortedTicketChains) => {
  const chainsWithStartDate = unsortedTicketChains?.map((chain) => {
    if (chain.ticketTiers.length === 1) {
      return {
        ...chain,
        startDate: new Date(chain.ticketTiers[0].ticketSalesPeriod.startDate),
      }
    }

    const startDate = chain.ticketTiers.reduce((minDate, ticket) => {
      const ticketDate = new Date(ticket.ticketSalesPeriod.startDate)
      return ticketDate < minDate ? ticketDate : minDate
    }, new Date(chain.ticketTiers[0].ticketSalesPeriod.startDate))

    return { ...chain, startDate }
  })

  return chainsWithStartDate?.sort((a, b) => a.startDate - b.startDate) || []
}

export default sortTicketChainsByStartDate

import { gql } from '@apollo/client'

const CREATE_BASKET = gql`
  mutation CREATE_BASKET($data: Ticketing_CreateBasketInput!) {
    Ticketing_createBasketV2(data: $data) {
      id
      total
      expiresAt
    }
  }
`

export default CREATE_BASKET

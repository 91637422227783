const formats = {
  Standard: 'eee, d MMM',
  StandardWithYear: 'eee, PP',
  Time: 'HH:mm',
  TimeNoColon: 'HHmm',
  DayAndTime: 'eee, HH:mm',
  DayAndMonth: 'eee, dd MMM',
  DayMonthAndTime: 'eee, dd MMM, HH:mm',
  DayMonthAndYear: 'eee, d MMM yyyy',
  DateFullMonthAndYear: 'd MMMM yyyy',
  DayDateMonthYearAndTime: 'eee, d MMM yyyy, HH:mm',
  DayOfMonthAndMonth: 'd MMM',
  DateOnly: 'PP',
  FullDateAndTime: 'eee, PP, HH:mm',
  MonthAndYear: 'MMMM yyyy',
  Month: 'MMMM',
  Day: 'cccc',
  DayOfMonth: 'd',
  MonthDayAndYear: 'PP',
  MonthDayYearAndTime: 'PP HH:mm',
  Year: 'yyyy',
  YearMonthDay: 'P',
  DayTime: 'eee HH:mm',
  News: 'NEWS',
  Events: 'EVENTS',
  MonthDay: 'MM/dd',
  TimeDayMonth: 'HH:mm dd MMM',
}

export default formats

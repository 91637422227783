// https://formatjs.io/docs/react-intl/components/#rich-text-formatting
import Link, { LinkProps } from '@/components/generic/link'

const Italic = (chunks: string[]) => <i>{chunks}</i>

const Bold = (chunks: string[]) => <b>{chunks}</b>

const Break = () => <br />

const getLink = (props: LinkProps) => (chunks: string[]) =>
  <Link {...props}>{chunks}</Link>

export { Break, Bold, Italic, getLink }

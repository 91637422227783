import {
  format as formatDate,
  addWeeks,
  isSameDay,
  isSameYear,
  isSameMonth,
} from 'date-fns'
import { toDateFormat } from 'lib/dateFormat'
import { useServerTime } from '@/context/ServerTimeContext'
import useDateFnsLocale from '@/hooks/useDateFnsLocale'
import formats from './date-formats'

const getNewsFormat = (date, today) => {
  if (isSameDay(toDateFormat(date), today)) return formats.Time

  if (new Date(date) >= addWeeks(today, -1)) return formats.DayAndTime

  return formats.StandardWithYear
}

const getEventsFormat = (date, today) => {
  if (isSameYear(toDateFormat(date), today)) return formats.Standard

  return formats.StandardWithYear
}

const useLocaleDateFormat = ({ date, format = formats.Standard }) => {
  const locale = useDateFnsLocale()

  const today = useServerTime()

  if (!date) {
    return null
  }

  const dateToUse = toDateFormat(date)

  let formatToUse
  switch (format) {
    case formats.News:
      formatToUse = getNewsFormat(date, today)
      break
    case formats.Events:
      formatToUse = getEventsFormat(date, today)
      break
    default:
      formatToUse = format
  }
  return localeDateFormat({ date: dateToUse, format: formatToUse, locale })
}

const useLocaleDateRangeFormat = ({
  startDate,
  endDate,
  format = formats.Standard,
}) => {
  const locale = useDateFnsLocale()
  const singleDate = useLocaleDateFormat({ date: startDate, format })
  if (!endDate) {
    return singleDate
  }

  const endDateFormatted = localeDateFormat({
    date: endDate,
    format: formats.DayOfMonthAndMonth,
    locale,
  })

  if (isSameMonth(toDateFormat(startDate), toDateFormat(endDate))) {
    const startDateFormatted = localeDateFormat({
      date: startDate,
      format: formats.DayOfMonth,
      locale,
    })
    return `${startDateFormatted} - ${endDateFormatted}`
  }

  const startDateFormatted = localeDateFormat({
    date: startDate,
    format: formats.DayOfMonthAndMonth,
    locale,
  })

  return `${startDateFormatted} - ${endDateFormatted}`
}

const localeDateFormat = ({ date, format = formats.Standard, locale }) =>
  formatDate(toDateFormat(date), format, {
    locale,
  })

export { localeDateFormat, useLocaleDateRangeFormat }
export default useLocaleDateFormat

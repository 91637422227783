import PropTypes from 'prop-types'
import { Alignment, Text, Box, variants } from '@resident-advisor/design-system'
import { toDateFormat } from 'lib/dateFormat'
import isMultiDayEvent from 'lib/isMultiDayEvent'

import eventUrlFormatters from 'lib/eventUrlFormatters'
import { eventsByAreaRoute } from 'lib/routes'
import DateComponent from '@/components/generic/date'
import Link from '@/components/generic/link'

const EventDate = ({ eventDate, start, end, area, variant }) => {
  const { formatDateUrl } = eventUrlFormatters({
    area,
    basePath: '/events',
    dynamicRouteFormatter: eventsByAreaRoute,
  })

  if (isMultiDayEvent(start, end)) {
    return (
      <MultiDate
        start={start}
        end={end}
        variant={variant}
        formatDateUrl={formatDateUrl}
      />
    )
  }

  const startFormatted = toDateFormat(start)
  const endFormatted = toDateFormat(end)

  return (
    <SingleDate
      eventDate={eventDate}
      start={startFormatted}
      end={endFormatted}
      variant={variant}
      formatDateUrl={formatDateUrl}
    />
  )
}

EventDate.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  eventDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  variant: PropTypes.string,
  area: PropTypes.shape({
    urlName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.shape({
      urlCode: PropTypes.string.isRequired,
    }),
  }),
}

EventDate.defaultProps = {
  variant: variants.text.s,
}

const MultiDate = ({ start, end, variant, formatDateUrl }) => (
  <Alignment flexDirection="column">
    <Link
      underline
      uppercase={false}
      color="primary"
      variant={variant}
      {...formatDateUrl(start)}
    >
      <DateComponent
        date={start}
        format={DateComponent.Formats.MonthDayAndYear}
        noStyle
      />
    </Link>
    <Time
      start={start}
      end={end}
      variant={variants.text.small}
      format={DateComponent.Formats.DayTime}
    />
  </Alignment>
)

MultiDate.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  formatDateUrl: PropTypes.func.isRequired,
}

const SingleDate = ({ eventDate, start, end, variant, formatDateUrl }) => (
  <Alignment flexDirection="column">
    <Link
      underline
      uppercase={false}
      color="primary"
      variant={variant}
      {...formatDateUrl(eventDate)}
    >
      <DateComponent
        date={eventDate}
        format={DateComponent.Formats.StandardWithYear}
        noStyle
      />
    </Link>
    <Time
      start={start}
      end={end}
      variant={variants.text.small}
      format={DateComponent.Formats.Time}
    />
  </Alignment>
)

SingleDate.propTypes = {
  eventDate: PropTypes.string.isRequired,
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.string.isRequired,
  formatDateUrl: PropTypes.func.isRequired,
}

const Time = ({ start, end, variant, format }) => (
  <Box pt={1}>
    <DateComponent
      date={start}
      format={format}
      uppercase={false}
      color="primary"
      variant={variant}
    />
    <Text variant={variant} color="primary">
      &nbsp;-&nbsp;
    </Text>
    <DateComponent
      date={end}
      format={format}
      uppercase={false}
      color="primary"
      variant={variant}
    />
  </Box>
)

Time.propTypes = {
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}

export default EventDate

import {
  eventsByAreaRoute,
  eventsForYouByAreaRoute,
  eventsRAPicksByAreaRoute,
  eventsJustAnnouncedByAreaRoute,
  DynamicRouteFormatter,
  eventsByGeoLocationRoute,
  eventsForYouByGeoLocationRoute,
  eventsJustAnnouncedByGeoLocationRoute,
  eventsRAPicksByGeoLocationRoute,
} from 'lib/routes'
import { EventListingsType } from '@/enums/event-listings-type'
import EventListingsLocationType from '@/enums/event-listings-location-type'

const getEventListingsRouteFormatter = (
  listingsType: EventListingsType,
  locationType: EventListingsLocationType
): DynamicRouteFormatter => {
  switch (locationType) {
    case EventListingsLocationType.geoLocation:
      return getEventListingsGeoLocationRouteFormatter(listingsType)
    default:
      return getEventListingsAreaRouteFormatter(listingsType)
  }
}

const getEventListingsAreaRouteFormatter = (
  listingsType: EventListingsType
): DynamicRouteFormatter => {
  switch (listingsType) {
    case EventListingsType.forYou:
      return (path, newQuery) =>
        eventsForYouByAreaRoute(`${path}/for-you`, newQuery)
    case EventListingsType.justAnnounced:
      return (path, newQuery) =>
        eventsJustAnnouncedByAreaRoute(`${path}/just-announced`, newQuery)
    case EventListingsType.raPicks:
      return (path, newQuery) =>
        eventsRAPicksByAreaRoute(`${path}/ra-picks`, newQuery)
    default:
      return eventsByAreaRoute
  }
}

const getEventListingsGeoLocationRouteFormatter = (
  listingsType: EventListingsType
): DynamicRouteFormatter => {
  switch (listingsType) {
    case EventListingsType.forYou:
      return (path, newQuery) =>
        eventsForYouByGeoLocationRoute(`${path}/for-you`, newQuery)
    case EventListingsType.justAnnounced:
      return (path, newQuery) =>
        eventsJustAnnouncedByGeoLocationRoute(
          `${path}/just-announced`,
          newQuery
        )
    case EventListingsType.raPicks:
      return (path, newQuery) =>
        eventsRAPicksByGeoLocationRoute(`${path}/ra-picks`, newQuery)
    default:
      return eventsByGeoLocationRoute
  }
}

export { getEventListingsGeoLocationRouteFormatter }
export default getEventListingsRouteFormatter

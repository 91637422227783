import {
  Detail,
  Box,
  Layout,
  Text,
  Grid,
  Column,
  variants,
  Alignment,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { clubByIdRoute } from 'lib/routes'
import Tracking from 'scripts/tracking'
import arrayHasData from 'lib/arrayHasData'
import messages from '@/messages/events'
import trackingIds from '@/tracking/tracking-ids'
import featureSwitches from '@/enums/feature-switches'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import TrackingExperimentVariantNames from '@/enums/tracking-experiment-variant-names'
import EventDto from '@/interfaces/gql/EventDto'
import Link from '@/components/generic/link'
import FormattedNumber from '@/components/generic/formatted-number'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import SavedEventsButton, {
  EventSavedSourceType,
  SavedEventsButtonType,
} from '@/components/user/pages/saved-events/saved-events-button/SavedEventsButton'
import EventDate from './EventDate'
import PromoterList from './PromoterList'
import InterestButton from '../interest-button'

const EventDetailBar = ({
  id,
  venue,
  date,
  startTime,
  endTime,
  promoters,
  interestedCount,
  isInterested,
  isSaved,
  hasSecretVenue,
  artists,
  tickets,
  isTicketed,
}: EventDetailBarProps) => {
  const intl = useIntl()

  const hasPromoters = arrayHasData(promoters)

  const { variant } = Tracking.useTrackingExperiment(
    featureSwitches.hideInterestedCount
  )
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)

  return (
    <Box pb={4} data-tracking-id={trackingIds.eventDetailbar}>
      <Layout>
        <Grid
          lCols={12}
          mCols={8}
          sCols={4}
          gridTemplateAreas={{
            s: sGTA(hasPromoters, enableSavedEvents),
            m: mGTA,
            l: lGTA,
          }}
        >
          <Column area={Areas.Venue}>
            <Detail title={intl.formatMessage(messages.venue)}>
              {venue.live ? (
                <Link
                  color="primary"
                  data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventVenueLink}
                  variant={variants.text.smallNormalToNarrowResponsive}
                  underline
                  {...clubByIdRoute(venue.contentUrl)}
                >
                  {venue.name}
                </Link>
              ) : (
                <Text variant={variants.text.smallNormalToNarrowResponsive}>
                  {venue.name}
                </Text>
              )}
              <Grid mCols={4} pt={1}>
                <Column mSpan={3}>
                  <Text color="primary" variant={variants.text.small}>
                    {hasSecretVenue
                      ? intl.formatMessage(messages.secretVenueInfo)
                      : venue.address}
                  </Text>
                </Column>
              </Grid>
            </Detail>
          </Column>
          <Column area={Areas.Date}>
            <Detail title={intl.formatMessage(messages.date)}>
              <EventDate
                area={venue.area}
                eventDate={date}
                start={startTime}
                end={endTime}
                variant={variants.text.smallNormalToNarrowResponsive}
              />
            </Detail>
          </Column>
          {hasPromoters && (
            <Column area={Areas.Promoter}>
              <Detail
                title={intl.formatMessage(messages.promoter, {
                  promoterCount: promoters.length,
                })}
              >
                <PromoterList items={promoters} />
              </Detail>
            </Column>
          )}
          {variant !== TrackingExperimentVariantNames.On && (
            <Column area={Areas.InterestedCount}>
              <Detail title={intl.formatMessage(messages.interested)}>
                <Grid sCols={2} mCols={enableSavedEvents ? 1 : 3}>
                  <Column sSpan={1} mSpan={1}>
                    <Alignment alignItems={{ s: 'start', m: 'center' }}>
                      <Text
                        pb={enableSavedEvents ? 0 : 2}
                        variant={variants.text.heading.m}
                        color="accent"
                        mr={enableSavedEvents ? 3 : 0}
                      >
                        <FormattedNumber>{interestedCount}</FormattedNumber>
                      </Text>
                      {enableSavedEvents && (
                        <SavedEventsButton
                          trackingData={{
                            venue,
                            artists,
                            promoters,
                            tickets,
                            isTicketed,
                            source: EventSavedSourceType.EventDetailHeader,
                          }}
                          eventId={id}
                          isInterested={isInterested}
                          interestedCount={interestedCount}
                          isSaved={isSaved}
                          type={SavedEventsButtonType.EventDetailHeaderIcon}
                        />
                      )}
                    </Alignment>
                  </Column>
                  {!enableSavedEvents && (
                    <Column mSpan={2}>
                      <InterestButton
                        id={id}
                        isInterested={isInterested}
                        interestedCount={interestedCount}
                      />
                    </Column>
                  )}
                </Grid>
              </Detail>
            </Column>
          )}
        </Grid>
      </Layout>
    </Box>
  )
}

const lGTA = `
  "venue venue venue venue date date date promoter promoter interestedCount interestedCount interestedCount"
`
const mGTA = `
  "venue venue date date promoter promoter interestedCount interestedCount"
`
const sGTA = (hasPromoters: boolean, savedEventsAreEnabled: boolean) => {
  if (savedEventsAreEnabled) {
    return `
    "venue venue date date"
    ${
      hasPromoters
        ? '"promoter promoter interestedCount interestedCount"'
        : '". . interestedCount interestedCount"'
    }
    `
  }
  return `
    "venue venue date date"
    ${hasPromoters ? '"promoter promoter . ."' : ''}
    "interestedCount interestedCount . ."
  `
}

const Areas = {
  Venue: 'venue',
  Date: 'date',
  Promoter: 'promoter',
  Cost: 'cost',
  InterestedCount: 'interestedCount',
}

type EventDetailBarProps = EventDto

export default EventDetailBar

import { memo } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import testIds from '@/enums/testIds'
import Iframe from '@/components/shared/iframe/Iframe'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import TICKETING_SYSTEM from '@/enums/ticketing-system'
import useTicketsPromoCode from './useTicketsPromoCode'
import EntireTicketsDemoIframe from './EntireTicketsDemoIframe'

const {
  publicRuntimeConfig: { LEGACY_IFRAME_BASE_PATH },
} = getConfig()

const TicketsIframe: React.FC<TicketsIframeProps> = memo(
  ({ event, id, redirectUrl, type, onLoad }) => {
    const promo = useTicketsPromoCode({
      promoCodeOverride: event.promoCode,
      ticketingSystem: event.ticketingSystem,
    })
    const { asPath } = useRouter()

    if (event.ticketingSystem === TICKETING_SYSTEM.entire) {
      return <EntireTicketsDemoIframe id="m" />
    }

    const url = getUrl(event.id, promo, redirectUrl, type, asPath)

    return (
      <Iframe
        title="Tickets"
        id={`#tickets-iframe-${id}`}
        noTranslate
        url={url}
        dataTestId={`${testIds.ticketsIframe}-${event.id}`}
        data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketsIframe(id)}
        onLoad={onLoad}
      />
    )
  }
)

const TicketsIframePropTypes = {
  id: PropTypes.string.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    promoCode: PropTypes.string,
    ticketingSystem: PropTypes.oneOf(Object.values(TICKETING_SYSTEM)),
  }),
  redirectUrl: PropTypes.string,
  type: PropTypes.string,
  onLoad: PropTypes.func,
}

TicketsIframe.propTypes = TicketsIframePropTypes
type TicketsIframeProps = InferProps<typeof TicketsIframePropTypes>

const getUrl = (
  eventId: string,
  promo: string,
  redirectUrl: string,
  type: string,
  backUrl?: string
) => {
  let url = `${
    LEGACY_IFRAME_BASE_PATH || ''
  }/widget/event/${eventId}/embedtickets`

  if (!promo && !redirectUrl && !type && !backUrl) {
    return url
  }
  let joinChar = '?'

  if (promo) {
    url = `${url}${joinChar}promo=${encodeURI(promo)}`
    joinChar = '&'
  }

  if (redirectUrl) {
    url = `${url}${joinChar}redirectUrl=${redirectUrl}`
    joinChar = '&'
  }

  if (type) {
    url = `${url}${joinChar}type=${type}`
    joinChar = '&'
  }

  if (backUrl) {
    url = `${url}${joinChar}backUrl=${backUrl}`
  }

  return url
}

export { getUrl }
export default TicketsIframe

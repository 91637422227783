import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { format } from 'date-fns'
import AreaDto from '@/interfaces/gql/AreaDto'
import EventListingDateQueryParameters from '@/enums/event-listing-date-query-parameters'
import EventListingsLocationType from '@/enums/event-listings-location-type'
import { EventListingsType } from '@/enums/event-listings-type'
import { getEventListingsGeoLocationRouteFormatter } from '@/components/events/pages/events-home/event-listings/hooks/useEventListingsUrlFormatters/getEventListingsRouteFormatter'
import { generateUrlForAreaAndCountry } from './utils'
import {
  DynamicRouteFormatter,
  FormattedRoute,
  eventsByAreaRoute,
} from './routes'
import arrayHasData from './arrayHasData'
import { toDateFormat } from './dateFormat'

const generateUrl = (
  route: string,
  area: AreaDto,
  locationType: EventListingsLocationType
) => {
  if (locationType === EventListingsLocationType.geoLocation) {
    return `${route}/location/near-you`
  }

  return generateUrlForAreaAndCountry('', area)
}

const buildQuery = (
  date: string,
  query: NextParsedUrlQuery,
  paramsToRemove?: string[]
) => {
  const dateQueryStringParams = date
    ? { [EventListingDateQueryParameters.StartDate]: date }
    : {}
  const queryStringParams = query ?? {}

  if (paramsToRemove) {
    paramsToRemove.forEach((param) => {
      delete queryStringParams[param]
    })
  }

  return {
    ...queryStringParams,
    ...dateQueryStringParams,
  }
}

const formatPath = (
  basePath: string,
  locationContentUrl: string,
  genre?: string[]
) => {
  const formattedPath = `${basePath}${locationContentUrl}`.toLowerCase()

  /**
   * If we have multiple genres we don't want to put these into the formatted
   * path for SEO and sitemap reasons, they should go into the query params instead
   */
  if (genre?.length === 1 && !!genre[0]) {
    return `${formattedPath}/${genre}`
  }

  return formattedPath
}

const formatDateForUrl = (value: Date) =>
  format(toDateFormat(value), 'yyyy-MM-dd')

const formatDateForQuery = (value: Date) =>
  value ? formatDateForUrl(value) : undefined

const eventUrlFormatters = ({
  startDate,
  area,
  genre,
  basePath,
  query,
  dynamicRouteFormatter,
  listingsType,
  locationType,
}: EventUrlFormattersOptions) => ({
  formatBaseUrl: () => {
    const locationContentUrl = generateUrl('', area, locationType)
    const path = `${basePath}${locationContentUrl}`.toLowerCase()
    const { latitude, longitude, distance } = query

    const queryParamsToKeep =
      locationType === EventListingsLocationType.geoLocation &&
      latitude &&
      longitude &&
      distance
        ? { latitude, longitude, distance }
        : {}

    return dynamicRouteFormatter(path, queryParamsToKeep)
  },

  formatDateUrl: (
    selectedDate?: Date,
    selectedEndDate?: Date,
    thisWeekend?: boolean
  ) => {
    const locationContentUrl = generateUrl('', area, locationType)
    const path = formatPath(basePath, locationContentUrl, genre)

    let queryParams = { ...query }
    if (selectedDate) {
      queryParams = {
        ...queryParams,
        [EventListingDateQueryParameters.StartDate]:
          formatDateForUrl(selectedDate),
      }
    } else {
      delete queryParams[EventListingDateQueryParameters.StartDate]
    }

    if (selectedEndDate) {
      queryParams = {
        ...queryParams,
        [EventListingDateQueryParameters.EndDate]:
          formatDateForUrl(selectedEndDate),
      }
    } else {
      delete queryParams[EventListingDateQueryParameters.EndDate]
    }

    if (thisWeekend) {
      queryParams = {
        ...queryParams,
        [EventListingDateQueryParameters.ThisWeekend]: 'true',
      }
    } else {
      delete queryParams[EventListingDateQueryParameters.ThisWeekend]
    }

    return dynamicRouteFormatter(path, queryParams)
  },

  formatLocationUrl: (locationContentUrl: string) => {
    const path = formatPath(basePath, locationContentUrl, genre)
    const queryParams = buildQuery(startDate, query, [
      'latitude',
      'longitude',
      'distance',
    ])

    if (locationType === EventListingsLocationType.geoLocation) {
      return eventsByAreaRoute(path, queryParams)
    }

    return dynamicRouteFormatter(path, queryParams)
  },

  formatGenreUrl: (selectedGenre: string | string[]) => {
    const locationContentUrl = generateUrl('', area, locationType)
    const path = formatPath(
      basePath,
      locationContentUrl,
      Array.isArray(selectedGenre) ? selectedGenre : [selectedGenre]
    )

    let newQuery = { ...query }

    // If you've selected multiple genres, they should go into the new query
    if (arrayHasData(selectedGenre, 2)) {
      newQuery = { ...newQuery, genre: selectedGenre }
    } else if (arrayHasData(query?.genre, 2)) {
      /**
       * Otherwise we've either selected a single genre or no genres, so
       * if we've previous selected multiple then remove them from the new query
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { genre: _genresToOmit, ...rest } = newQuery
      newQuery = { ...rest }
    }

    return dynamicRouteFormatter(path, buildQuery(startDate, newQuery))
  },

  /**
   * Generic function to create a formatted route with an updated value for any
   * query parameter that doesn't appear in the route parameters (e.g. not `[area]`)
   *
   * @param queryParam The query parameter you want to update in the formatted route
   * @param updatedValue The new value for the query parameter that you want to appear
   *  in the formatted route
   * @returns {FormattedRoute} The formatted route containing the updated query parameter
   */
  formatQueryParamUrl: (
    queryParam: string,
    updatedValue: string
  ): FormattedRoute => {
    const locationContentUrl = generateUrl('', area, locationType)
    const path = formatPath(basePath, locationContentUrl, genre)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [queryParam]: _queryValue, ...rest } = query

    if (!updatedValue) return dynamicRouteFormatter(path, { ...rest })

    const queryParams = buildQuery(startDate, {
      ...rest,
      [queryParam]: updatedValue.toString(),
    })

    return dynamicRouteFormatter(path, queryParams)
  },

  formatGeoLocationUrl: (
    latitude: string,
    longitude: string,
    distance: string
  ) => {
    const locationContentUrl = generateUrl(
      '',
      area,
      EventListingsLocationType.geoLocation
    )
    const path = formatPath(basePath, locationContentUrl, genre)
    const queryParams = buildQuery(startDate, {
      ...query,
      latitude,
      longitude,
      distance,
    })

    return getEventListingsGeoLocationRouteFormatter(listingsType)(
      path,
      queryParams
    )
  },
})

type EventUrlFormattersOptions = {
  startDate?: string
  area: AreaDto
  genre?: string[]
  basePath: string
  query?: NextParsedUrlQuery
  dynamicRouteFormatter: DynamicRouteFormatter
  listingsType?: EventListingsType
  locationType?: EventListingsLocationType
}

export { formatDateForUrl, formatDateForQuery }
export default eventUrlFormatters

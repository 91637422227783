import { gql } from '@apollo/client'

const GET_TICKETS_FOR_USER_EVENT = gql`
  query GET_TICKETS_FOR_USER_EVENT(
    $status: [Ticketing_OrderStatus!]
    $eventId: String
  ) {
    Ticketing_me {
      id
      orders(status: $status, eventId: $eventId) {
        id
        orderItems {
          id
          ticketTier {
            id
            ticketCost {
              id
              type
            }
          }
        }
      }
    }
  }
`

export default GET_TICKETS_FOR_USER_EVENT
